import TopXConfigModel from "fansunited-sdk-esm/Core/Namespaces/TopX/Models/Config/TopXConfigModel";
import TopXFeaturesModel from "../../../models/features/topX/TopXFeaturesModel";
import FeaturesBuilder from "../FeaturesBuilder";
import FansUnitedSDKModel from "fansunited-sdk-esm/Core/Global/Models/FansUnitedSDKModel";

export default class TopXFeaturesBuilder extends FeaturesBuilder {
  private topXConfigFeatures: TopXConfigModel;
  private topXFeaturesModel: TopXFeaturesModel = new TopXFeaturesModel();

  constructor(sdk: FansUnitedSDKModel, topXConfigFeatures: TopXConfigModel) {
    super(sdk);
    this.topXConfigFeatures = topXConfigFeatures;
  }

  public build = async () => {
    this.topXFeaturesModel.enabled = this.topXConfigFeatures.enabled;
    this.topXFeaturesModel.minFixtures = this.topXConfigFeatures.minFixtures;
    this.topXFeaturesModel.maxFixtures = this.topXConfigFeatures.maxFixtures;
    this.topXFeaturesModel.authRequirement = this.topXConfigFeatures.authRequirement;
    this.topXFeaturesModel.competitionsWhitelist = await this.getCompetitions(
      this.topXConfigFeatures.competitionsWhitelist
    );

    return this.topXFeaturesModel;
  };
}
