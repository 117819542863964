export interface IEitherOrType {
  id: string;
  label: string;
}

export default class EitherOrFeatureModel {
  enabled: boolean = false;
  types: IEitherOrType[] = [];
  authRequirement: string | null = null;
}
