import React from "react";
import ValueSelect from "../../../../Common/ValueSelect";

type AuthRequirementProps = {
  authRequirement: string | null;
  options: string[];
  isClearable?: boolean;
  onChange: (value: string | null) => void;
};

const AuthRequirement: React.FC<AuthRequirementProps> = ({
  authRequirement,
  options,
  isClearable = false,
  onChange,
}) => {
  return (
    <div className="mb-2 border border-gray-200 rounded-lg p-2 bg-blueGray-50">
      <label className="font-bold mb-2">Auth requirement</label>
      <p className="mb-2">Choose the type of authentication to be used.</p>
      <ValueSelect
        value={authRequirement}
        options={options}
        onChange={onChange}
        isClearable={isClearable}
      />
    </div>
  );
};

export default AuthRequirement;
