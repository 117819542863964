import dayjs from "dayjs";
import CompetitionBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionBasicModel";
import PlayerBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Player/PlayerBasicModel";
import TeamBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Team/TeamBasicModel";
import { camelCase, snakeCase } from "lodash";
import { placeholders } from "../assets/placeholders/placeholders";
import SelectOption from "../models/SelectOption/SelectOption";
import { SnakeType } from "../types/types";
import { InfrastructureType } from "../models/Clients/ClientsBasicInfo";
import PlayerAssetsModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Player/PlayerAssetsModel";
import AssetsModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/AssetsModel";

export const parseJwt = (token: string) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const linkStyle = (page: string) =>
  `text-xs uppercase py-2 font-bold block mr-2 flex items-center text-nowrap flex flex-wrap
    ${
      window.location.href.indexOf(`/${page}`) !== -1
        ? "text-lightBlue-500 hover:text-lightBlue-600"
        : "text-blueGray-700 hover:text-blueGray-500"
    }`;

export const iconStyle = (
  icon: string,
  page: string
) => `fas ${icon} mr-2 w-5 text-sm " +
${
  window.location.href.indexOf(`/${page}`) !== -1
    ? "opacity-75"
    : "text-blueGray-300"
}`;

export const remapKeysToSpecificCase = (obj: any, type: SnakeType): any => {
  if (Array.isArray(obj)) {
    return obj.map((v) => remapKeysToSpecificCase(v, type));
  } else if (obj != null && obj.constructor === Object) {
    if (type === "camel")
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: remapKeysToSpecificCase(obj[key], type),
        }),
        {}
      );
    else if (type === "snake")
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [snakeCase(key)]: remapKeysToSpecificCase(obj[key], type),
        }),
        {}
      );
  }

  return obj;
};

/*
  Used as a labels in chart's xAxis data when we have a report for the last 28 days. The result will be a string array. For example:
  [27 Oct, 28 Oct, ...]
*/
export const getLast28Days = (): string[] => {
  let days: string[] = [];
  const now = new Date();

  for (let i = 28; i > 0; i--) {
    const xDaysBehind = dayjs().subtract(i, "days").toDate();

    const month = xDaysBehind.toLocaleString("default", { month: "short" });
    const day = xDaysBehind.getDate().toString() + " " + month;

    days.push(day);
  }

  return days;
};

/*
  Makes first letter of a word capital.
*/
export const upperCase = (word: string) => {
  const wordChars = word.split("");
  wordChars[0] = wordChars[0].toUpperCase();

  return wordChars.join("");
};

/*
  Makes first letter of all words capital.
*/
export const transformFromSnakeCaseToUpperCaseAllWords = (words: string) => {
  if (!words) return "";

  const wordChars = words.split("_");
  const transformedWords = wordChars.map((word) => upperCase(word));

  return transformedWords.join(" ");
};

/*
  Formats the iso date string we receive from API and formated like "01 January 2022"
*/
export const formatProfileDate = (isoDate: string) => {
  const format = "DD MMMM YYYY";

  return dayjs(isoDate).format(format).toString();
};

/*
  Format entity model to select option for react-select
*/
export const formatOptions = (
  entity: CompetitionBasicModel | TeamBasicModel | PlayerBasicModel
) => {
  const option = new SelectOption();

  option.label = entity.name;
  option.value = entity.name;
  option.id = entity.id;

  //@ts-ignore
  if (entity.birthDate) {
    const playerAssets = entity.assets as PlayerAssetsModel;
    option.logo = playerAssets?.headshot || placeholders.player;
  } else {
    const assets = entity.assets as AssetsModel;
    option.logo = assets?.logo || "";
  }

  option.countryName = entity.country?.name || "No country name";
  option.countryFlag = entity.country?.assets?.flag || placeholders.country;

  return option;
};

export const extractEntityIds = (
  entity: CompetitionBasicModel[] | TeamBasicModel[]
) => {
  if (entity && entity.length) {
    return entity.map(
      (entity: CompetitionBasicModel | TeamBasicModel) => entity.id
    );
  }

  return [];
};

/**
 * For some title we will use the provided id field from API. They all will be in format some_value.
 */

export const mapIdValueToTitle = (value: any) => {
  return value.id.split("_").join(" ");
};

/**
 * Loops through object's properties and deletes provided property field.
 * @param obj Object to be updated.
 * @param deleteProperty Field from the object to be deleted.
 * @returns Updated object.
 */

export const removePropertyFromObject = (
  obj: { [key: string]: any },
  deleteProperty: string
) => {
  Object.keys(obj).forEach(
    (key) =>
      (key === deleteProperty && delete obj[key]) ||
      (obj[key] &&
        typeof obj[key] === "object" &&
        removePropertyFromObject(obj[key], deleteProperty))
  );
  return obj;
};

/**
 * Used to extract unselected competitions for react-select component.
 * @param fullCoverageCompetitions All competitions who are available to choose.
 * @param competitionsWhitelist Competitions who are already choosen.
 * @returns Competitions who aren't in whitelist and are available for selection.
 */

export const extractUnselectedCompetitions = (
  fullCoverageCompetitions: CompetitionBasicModel[],
  competitionsWhitelist: string[]
) => {
  if (!fullCoverageCompetitions) return [];

  return fullCoverageCompetitions.filter(
    (fullCoverageCompetition: CompetitionBasicModel) =>
      competitionsWhitelist.every(
        (competitionId: string) => fullCoverageCompetition.id !== competitionId
      )
  );
};

/**
 * In Firefox input type number accepts all kinds of symbols so we need regex validation for every symbol
 * to be sure that client will type only numbers.
 */
export const inputNumber = (event: any, isPoints: boolean) => {
  // When managing points for external points negative values can be accepted.
  const regexp = isPoints ? /^-?\d*$/ : /^[0-9]+$/;
  event = event || window.event;
  // Char code 8 stands for Backspace
  const backspaceCharCode = 8;
  // Char code 45 stands for - sign
  const minusSignCharCode = 189;
  const charCode =
    typeof event.which == "undefined" ? event.keyCode : event.which;
  const charStr = String.fromCharCode(charCode);
  const isNonMatchedString = !charStr.match(regexp);

  if (!isPoints && charCode !== backspaceCharCode && isNonMatchedString) {
    event.preventDefault();
  }

  if (
    isPoints &&
    charCode !== backspaceCharCode &&
    charCode !== minusSignCharCode &&
    isNonMatchedString
  ) {
    event.preventDefault();
  }
};

/**
 * Format the provided period dates in specified format token. Used in titles in report screen.
 * @param fromDate Date given from react-flatpickr.
 * @param toDate Date given from react-flatpickr.
 * @returns Two dates in concrete format.
 */
export const formatProvidedPeriodReport = (
  fromDate: string,
  toDate: string,
  period: "selected" | "compared_to"
) => {
  const dayjsFormatToken = "DD MMM YYYY"; // 01 Jan 2023
  let newFromDate: string = "";
  let newToDate: string = "";

  if (period === "selected") {
    newFromDate = fromDate
      ? dayjs(fromDate).format(dayjsFormatToken)
      : dayjs().subtract(31, "day").format(dayjsFormatToken);
    newToDate = toDate
      ? dayjs(toDate).format(dayjsFormatToken)
      : dayjs().subtract(1, "day").format(dayjsFormatToken);
  } else {
    newFromDate = fromDate
      ? dayjs(fromDate).format(dayjsFormatToken)
      : dayjs().subtract(62, "day").format(dayjsFormatToken);
    newToDate = toDate
      ? dayjs(toDate).format(dayjsFormatToken)
      : dayjs().subtract(31, "day").format(dayjsFormatToken);
  }

  return [newFromDate, newToDate];
};

/**
 * Format the provided period dates in specified format token. Used on labels in all charts.
 */
export const formatDateLabelChart = (date: string) => {
  const dayjsFormatToken = "DD MMM YYYY, dddd"; // 01 Jan 2023, Monday

  return dayjs(date).format(dayjsFormatToken);
};

/**
 * Used to get a key from Map by it value.
 */
export const getKeyFromMapByValue = (
  map: Map<string, string>,
  searchValue: string
) => {
  for (let [key, value] of Array.from(map.entries())) {
    if (value === searchValue) return key;
  }
};

/**
 * Sorting object's keys in ascending order depending on their number value
 * @param object Object keys to be sorted
 * @returns Sorted object's keys in ascending order by their value.
 */

export const sortObjectPropertiesByTheirValue = (object: {
  [key: string]: number;
}) => {
  return Object.fromEntries(
    Object.entries(object).sort(([, a], [, b]) => b - a)
  );
};

/**
 * Used to set the default period (latest 30 days) for reports
 */

export const setDefaultPeriodFlatpickrDate = () => {
  const startDate = dayjs().subtract(31, "day").toDate();
  const endDate = dayjs().subtract(1, "day").toDate();

  return [startDate, endDate];
};

/**
 * Used to set the default compared to period (30 days before latest 30 days) for flatpickr instance
 */

export const setDefaultComparedToFlatpickrDate = () => {
  const startDate = dayjs().subtract(62, "day").toDate();
  const endDate = dayjs().subtract(32, "day").toDate();

  return [startDate, endDate];
};

/**
 * Used to construct default period for Reporting API call. The data will be set as compared to in chart dataset.
 */

export const setDefaultCoparedToDateApi = () => {
  const fromDate = dayjs().subtract(62, "day").format("YYYY-MM-DD");
  const toDate = dayjs().subtract(32, "day").format("YYYY-MM-DD");

  return { fromDate, toDate };
};

/**
 * Use '#' as a separtor for the two different periods datasets.
 */

export const constructLabelsChart = (
  selectedPeriodBreakdown: any[],
  previousPeriodBreakdown: any[]
): string[] => {
  if (previousPeriodBreakdown) {
    if (selectedPeriodBreakdown.length === previousPeriodBreakdown.length) {
      return selectedPeriodBreakdown?.map(
        (value: { date: string; users: any }, idx: number) =>
          `${formatDateLabelChart(value.date)}#${formatDateLabelChart(
            previousPeriodBreakdown[idx].date
          )}`
      );
    } else if (
      selectedPeriodBreakdown.length > previousPeriodBreakdown.length
    ) {
      // Sometimes API returns for selected period same breakdown as in previous period
      const newSelectedPeriodBreakdown = selectedPeriodBreakdown.filter(
        (breakdown: any) =>
          previousPeriodBreakdown.every(
            (previousBreakdown) => previousBreakdown.date !== breakdown.date
          )
      );
      return newSelectedPeriodBreakdown?.map(
        (value: { date: string; users: any }, idx: number) =>
          `${formatDateLabelChart(value.date)}#${formatDateLabelChart(
            previousPeriodBreakdown[idx]?.date
          )}`
      );
    }
    // Sometimes API returns for previous period same breakdown as in selected period
    const newPreviousPeriodBreakdown = previousPeriodBreakdown.filter(
      (previousBreakdown: any) =>
        selectedPeriodBreakdown.every(
          (breakdown) => breakdown.date !== previousBreakdown.date
        )
    );

    return selectedPeriodBreakdown?.map(
      (value: { date: string; users: any }, idx: number) =>
        `${formatDateLabelChart(value.date)}#${formatDateLabelChart(
          newPreviousPeriodBreakdown[idx].date
        )}`
    );
  }

  return selectedPeriodBreakdown?.map((value: { date: string }) =>
    formatDateLabelChart(value.date)
  );
};

export const initEnvironment = (infrastructure: InfrastructureType) => {
  switch (infrastructure) {
    case "DEV":
      return "dev";
    case "LOCAL":
      return "dev";
    case "STAGE":
      return "staging";
    case "PROD":
      return "prod";
    case "PROD_WATG":
      return "watg";
  }
};
