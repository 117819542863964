import { useContext, useState } from "react";
import Spinner from "../../../Spinner/Spinner";
import Enabled from "../Common/Enabled";
import UpdateButton from "../../../Buttons/UpdateButton";
import AddButton from "../../../Buttons/AddButton";
import RemoveButton from "../../../Buttons/RemoveButton";
import { GlobalStatesContext } from "../../../../providers/GlobalStatesProvider";
import { toast } from "react-toastify";
import {
  AuthRequirementEnum,
  ERROR_MESSAGES,
  FansUnitedFeatures,
  SUCCESS_MESSAGES,
} from "../../../../enums/enums";
import { ApiContext } from "../../../../providers/ApiProvider";
import EitherOrFeatureModel, {
  IEitherOrType,
} from "../../../../models/features/eitherOr/EitherOrFeatureModel";
import AuthRequirement from "../Common/AuthRequirement/AuthRequirement";

type FeaturesEitherOrProps = {
  feature: EitherOrFeatureModel;
  isFeaturesEdited: { current: boolean };
};

const FeaturesEitherOr: React.FC<FeaturesEitherOrProps> = ({
  feature,
  isFeaturesEdited,
}) => {
  const { isLoading, setIsLoading } = useContext(GlobalStatesContext);
  const { clientHttps } = useContext(ApiContext);

  const [copyFeature, setCopyFeature] = useState<EitherOrFeatureModel>(feature);
  const [types, setTypes] = useState<IEitherOrType[]>(
    copyFeature ? copyFeature.types : []
  );
  const [authRequirement, setAuthRequirement] = useState<string | null>(
    copyFeature && copyFeature.authRequirement
      ? copyFeature.authRequirement.toLocaleLowerCase()
      : null
  );

  const addType = () => {
    const newType: IEitherOrType = { id: "", label: "" };
    setTypes((prevState) => {
      return [...prevState, newType];
    });
    setCopyFeature((prevState) => {
      prevState.types.push(newType);

      return prevState;
    });
  };

  const removeType = (index: number) => {
    const newTypes = Array.from(types);
    newTypes.splice(index, 1);
    setTypes(newTypes);
    setCopyFeature((prevState) => {
      prevState.types.splice(index, 1);

      return prevState;
    });
  };

  const onChangeId = (
    event: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    const newTypes = JSON.parse(JSON.stringify(types));
    newTypes[idx].id = event.target.value;
    setTypes(newTypes);
  };

  const onChangeLabel = (
    event: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    const newTypes = JSON.parse(JSON.stringify(types));
    newTypes[idx].label = event.target.value;
    setTypes(newTypes);
  };

  const updateFeature = async () => {
    setIsLoading(true);
    const requestBody = {
      enabled: copyFeature.enabled,
      types,
      auth_requirement: authRequirement,
    };

    try {
      await clientHttps.updateFeaturesForClient(
        requestBody,
        FansUnitedFeatures.EITHER_OR
      );
      toast.success(SUCCESS_MESSAGES.FEATURES_EITHER_OR_UPDATE);
      isFeaturesEdited.current = !isFeaturesEdited.current;
    } catch (e) {
      console.error(e);
      toast.error(ERROR_MESSAGES.EITHER_OR_FEATURE_UPDATE);
    }

    setIsLoading(false);
  };

  return (
    <>
      {copyFeature ? (
        <>
          <Enabled value={copyFeature.enabled} />
          <div className="mb-2 border border-gray-200 rounded-lg p-2 bg-blueGray-50">
            <h3 className="font-bold mb-2">Types</h3>
            <p className="mb-2">
              You can configure types for your Either/Or games by providing ID
              and label
            </p>
            <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-2">
              {copyFeature.types.map((type: IEitherOrType, idx: number) => (
                <div className="bg-blueGray-200 mb-2" key={`${type.id}-${idx}`}>
                  <div className="flex flex-col p-2">
                    <div className="flex flex-col mb-2">
                      <div className="flex justify-between items-center mb-2">
                        <label className="font-bold">ID</label>
                        <RemoveButton onClick={() => removeType(idx)} />
                      </div>
                      <input
                        className="p-3"
                        defaultValue={type.id}
                        onChange={(event) => onChangeId(event, idx)}
                        type="text"
                      />
                    </div>
                    <div className="flex flex-col mb-2">
                      <label className="font-bold mb-2">Label</label>
                      <input
                        className="p-3"
                        defaultValue={type.label}
                        onChange={(event) => onChangeLabel(event, idx)}
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              ))}
              <AddButton onClick={addType} />
            </div>
          </div>
          <AuthRequirement
            authRequirement={authRequirement}
            onChange={(value) => setAuthRequirement(value)}
            options={Object.values(AuthRequirementEnum)}
            isClearable={true}
          />
          <UpdateButton
            label={"Update"}
            onClick={updateFeature}
            isLoading={isLoading}
          />
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default FeaturesEitherOr;
