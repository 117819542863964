import React from "react";
import Select from "react-select";
import ReactSelectBaseOption from "../../models/SelectOption/ReactSelectBaseOption";
import { webhookTypeSelectStyles } from "../../constants/styles";
import { transformFromSnakeCaseToUpperCaseAllWords } from "../../helpers/helpers";

const transformValueToSelectOption = (value: string): ReactSelectBaseOption => {
  return {
    id: value,
    value: value,
    label: transformFromSnakeCaseToUpperCaseAllWords(value),
  };
};

const transformValuesToSelectOptions = (
  values: string[]
): ReactSelectBaseOption[] => {
  return values.map((v: string) => {
    return {
      id: v,
      value: v,
      label: transformFromSnakeCaseToUpperCaseAllWords(v),
    };
  });
};

type ValueSelectProps = {
  value: string;
  options: string[];
  isClearable?: boolean;
  placeholder?: string;
  onChange: (value: string) => void;
};

const ValueSelect: React.FC<ValueSelectProps> = ({
  value,
  options,
  isClearable = false,
  placeholder = "",
  onChange,
}) => {
  const onChangeHandler = (selectOption: ReactSelectBaseOption) =>
    onChange(selectOption?.value || null);

  return (
    <Select
      getOptionValue={(option) => option.id} // Provides an unique key value to every multi value option
      styles={webhookTypeSelectStyles}
      onChange={onChangeHandler}
      defaultValue={transformValueToSelectOption(value)}
      options={transformValuesToSelectOptions(options)}
      isClearable={isClearable}
      placeholder={placeholder}
    />
  );
};

export default ValueSelect;
